<template>
    <div id="declaration-prestations" class="pt-3 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Pointages
        </div>
        <b-card
            class="mt-5"
            id="prestation-form"
            border-variant="secondary"
            :header="title"
            header-border-variant="secondary"
            align="left"
        >
            <b-card-text class="p-3">
                <b-form>
                    <b-form-group
                        label-cols="5"
                        label="Client"
                    >
                        <b-form-select :options="clientOptions" v-model="form.clientId" :disabled="mode === 'non-declare'"></b-form-select>
                    </b-form-group>
                    <b-form-group
                        label-cols="5"
                        label="Nombre d'heures"
                    >
                        <b-form-select :options="nbHeureOptions" v-model="form.nbHeures" @change="changeDateFin" :disabled="mode === 'non-declare'"></b-form-select>
                    </b-form-group>
                    <b-form-group
                        label-cols="5"
                        label="Date de début"
                    >
                        <b-form-input :value="formatDate(form.dateDebut)" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label-cols="5"
                        label="Date de fin"
                    >
                        <b-form-input :value="formatDate(form.dateFin)" disabled></b-form-input>
                    </b-form-group>
                </b-form>
            </b-card-text>
            <b-card-footer class="p-0">
                <b-button block variant="success m-0" @click="submit">
                    <div class="d-flex align-items-center justify-content-center m-0">
                        <span class="align-items-center d-flex"><i class="fas fa-hand-sparkles mr-3"></i> Déclarer le pointage</span>
                    </div>
                </b-button>
            </b-card-footer>
        </b-card>
        <div v-if="mode === 'non-declare'">
            <a class="d-block pt-3 pb-2 cursor-pointer callMerciBtn">
                <div class="bg-white card text-danger">
                    <div class="card-body">
                        <h5 class="d-flex align-items-center justify-content-center m-0">
                            <span><i class="fas fa-edit mr-2"></i> Je modifie la prestation</span>
                        </h5>
                    </div>
                </div>
            </a>
            <a class="d-block pt-1 pb-3 cursor-pointer callMerciBtn">
                <div class="bg-danger card text-white">
                    <div class="card-body">
                        <h5 class="d-flex align-items-center justify-content-center m-0">Je n'ai pas effectué la prestation</h5>
                    </div>
                </div>
            </a>
        </div>
        <div v-else>
            <b-row class="text-center text-white">
                <b-col sm="12" class="text-center mt-3">Un problème ?</b-col>
            </b-row>
            <a class="d-block pt-3 pb-3 cursor-pointer callMerciBtn">
                <div class="bg-white card text-info">
                    <div class="card-body">
                        <h4 class="d-flex align-items-center justify-content-center m-0">
                            <span><i class="fas fa-phone-alt mr-2"></i> Appeler {{getCompanyName()}}</span>
                        </h4>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    import router from '../../router'
    export default {
        name: "DeclarationPrestations",
        mounted () {
            this.$emit('menu', 'pointages')
            if (router.currentRoute.params.mode === 'declaration') {
                this.title = 'Déclarer une prestation'
                this.mode = 'declaration'
            }
        },
        data () {
            return {
                mode: 'non-declare',
                title: 'Prestation non-déclarée',
                clientOptions: [
                    {
                        value: 1,
                        text: 'Mme Sonnero'
                    },
                    {
                        value: 2,
                        text: 'Mme Capucine'
                    },
                ],
                nbHeureOptions: [
                    {
                        value: 1,
                        text: 1
                    },
                    {
                        value: 2,
                        text: 2
                    },
                    {
                        value: 3,
                        text: 3
                    },
                    {
                        value: 4,
                        text: 4
                    },
                    {
                        value: 5,
                        text: 5
                    },
                ],
                form: {
                    clientId: 1,
                    nbHeures: 3,
                    dateDebut: this.$moment(),
                    dateFin: this.$moment().add(3, 'hours')
                }
            }
        },
        methods: {
            getCompanyName() {
                if (!location.host.includes('family')) {
                    return "MERCI+"
                } else {
                    return "Family Sphere"
                }
            },
            formatDate (date) {
                return date.format('dddd D MMMM HH:mm')
            },
            changeDateFin (event) {
                this.form.dateFin = this.$moment(this.form.dateDebut)
                this.form.dateFin.add(event, 'hours')
            },
            show () {
                this.$refs.declaration.show()
            },
            hide () {
                this.$refs.declaration.hide()
            },
            getBack () {
                router.go(-1)
            },
            submit () {
                console.log({
                    client: this.form.clientId,
                    nbHeures: this.form.nbHeures,
                    dateDebut: this.form.dateDebut.format('YYYY-MM-DD HH:mm:ss'),
                    dateFin: this.form.dateFin.format('YYYY-MM-DD HH:mm:ss')
                })
                this.getBack()
            }
        }
    }
</script>

<style scoped>
    #ariane {
        text-align: center;
        padding: 10px;
        position: fixed;
        font-size: 22px;
        top: 45px;
        left: 0;
        right: 0;
        z-index: 2;
    }
    @media (min-width: 992px) {
        #ariane {
            top: 80px;
        }
    }
    #ariane button {
        position: absolute;
        left: 15px;
        background: transparent;
        border: none;
    }
    #ariane button:focus {
        box-shadow: none;
    }
    #prestation-form {
        border-radius: 0.5em;
    }
    #prestation-form .card-header {
        background: #e9f3ff;
        border-radius: 0.5em 0.5em 0 0;
        border: none;
    }
    #prestation-form .card-body {
        padding: 0;
    }
    #prestation-form button {
        border-radius: 0 0 0.3em 0.3em;
        font-size: 20px;
    }
    #prestation-form input {
        background: transparent;
        border: none;
    }
    #prestation-form select {
        border-radius: 100px;
        background: #e9f3ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%232f3744' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    }
    #prestation-form select:focus {
        box-shadow: none;
        border: 1px solid #ced4da;
    }
</style>
